import React, { useState, useEffect, useRef } from "react"
import ReactModal from "react-modal"
import Slider from "react-slick"
import { isVisibleIn } from "@utils/region"
import CTA from "@components/blocks/cta"
import CustomArrow from "@components/blocks/custom-arrow"
import RichText from "@components/rich-text"
import Container from "@components/container"
import { ReactComponent as Close } from "@svgs/close.svg"
import useCarousel from "@src/hooks/useCarousel"
import { useSiteSettingsStore } from "@src/hooks/useStore"

const AnnouncementBar = () => {
  const sliderRef = useRef()

  const { siteSettings } = useSiteSettingsStore(["siteSettings"])

  const data = siteSettings?.announcementBar

  const { ignoreHiddenItems } = useCarousel()

  const [slides, setSlides] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [modalData, setModalData] = useState({ title: "", content: "" })

  useEffect(() => {
    const webUrl = new URL(window.location)
    const searchParams = webUrl.searchParams

    const filteredSlides = data?.slides
      ?.filter(slide => {
        if (!isVisibleIn(slide.visibilityRegions)) return false

        if (slide?.checkUTMParams?.length > 0) {
          const displaySlide = slide.checkUTMParams.some(
            param => param.show && param.value === searchParams.get(param.key)
          )
          if (!displaySlide) return false
        }

        if (slide.dontShowIn?.includes(webUrl.pathname)) return false
        if (
          slide.showIn === "others" &&
          !slide?.otherPages.includes(webUrl.pathname)
        )
          return false

        return true
      })
      .filter(Boolean)

    // Only update slides if they actually change
    if (JSON.stringify(filteredSlides) !== JSON.stringify(slides)) {
      setSlides(filteredSlides)
    }

    // Only run ignoreHiddenItems if slides are present and sliderRef is set
    if (sliderRef?.current && slides?.length > 0) {
      ignoreHiddenItems(sliderRef, ".announcement-content, .btn")
    }
  }, [data, ignoreHiddenItems, slides])

  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <CustomArrow
        onClick
        classList="next-arrow absolute right-0 top-1/2 -translate-y-1/2 translate-x-2 p-2 w-7 md:w-8"
        ariaLabel="Announcement Banner Next Arrow"
      />
    ),
    prevArrow: (
      <CustomArrow
        onClick
        classList="prev-arrow absolute left-0 top-1/2 -translate-y-1/2 -translate-x-2 p-2 w-7 md:w-8 z-[1] rotate-180"
        ariaLabel="Announcement Banner Previous Arrow"
      />
    ),
    afterChange: () => {
      if (sliderRef?.current) {
        ignoreHiddenItems(sliderRef, ".announcement-content, .btn")
      }
    },
  }

  if (slides?.length === 0) return null

  return (
    <>
      <div
        className="bg-safety-green fixed w-full top-0 z-[52]"
        aria-label="Announcement Banner"
        tabIndex={0}
        aria-hidden={false}
      >
        <Container>
          <Slider
            {...settings}
            className="px-8 py-2 m-auto w-full xl:w-full"
            ref={sliderRef}
          >
            {slides?.map((slide, key) => (
              <div
                key={key}
                className="!flex flex-col md:flex-row md:gap-2 items-center justify-center"
              >
                <p
                  className="text-center text-sm text-white leading-6 announcement-content"
                  aria-label={slide.mainText}
                  tabIndex={0}
                >
                  {slide.mainText}
                </p>
                <div className="flex flex-row items-center justify-center">
                  {slide?.modal?.show && (
                    <>
                      <button
                        onClick={() => {
                          setIsOpen(true)
                          setModalData({
                            title: slide?.modal?.title,
                            content: slide?.modal?.richText,
                          })
                        }}
                        className="cursor-pointer mr-2 w-4 h-4 max-w-full max-h-full"
                        aria-label="Learn More"
                      >
                        <img
                          src={slide?.modal?.icon?.asset?.url}
                          alt="Info icon"
                        />
                      </button>
                    </>
                  )}
                  <CTA
                    cta={slide.cta}
                    className="!p-0 pr-1 underline text-sm whitespace-nowrap"
                    buttonClassName="!p-0 text-sm text-white whitespace-nowrap leading-6 bg-transparent border-0 font-normal flex items-center gap-1 fill-white arrow-small"
                    arrowFill="white"
                  />
                </div>
              </div>
            ))}
          </Slider>
        </Container>
      </div>
      <ReactModal
        className="absolute bg-white overflow-auto outline-none top-1/2 left-1/2 right-auto bottom-auto mr-[-50%] translate-x-[-50%] translate-y-[-50%] max-w-[41.875rem] w-[93%] md:w-full max-h-[92%] p-0"
        overlayClassName="fixed top-0 right-0 left-0 bottom-0 bg-black bg-opacity-20 opacity-100 z-10"
        bodyOpenClassName="overflow-hidden"
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        <div className="flex justify-center items-center rounded-t px-[1.25rem] md:px-[3.75rem] py-5 sticky top-0 bg-white z-10">
          <p className="text-xl">{modalData?.title}</p>
          <button
            className="p-1 absolute right-3 top-3 md:right-5 md:top-5"
            onClick={() => setIsOpen(false)}
          >
            <Close className="w-4 h-4 cursor-pointer" />
          </button>
        </div>
        <div className="px-5 md:px-14 overflow-y-auto">
          <div className="text-md text-dark-navy">
            <RichText text={modalData?.content} className="max-w-full" />
          </div>
        </div>
      </ReactModal>
    </>
  )
}

export default AnnouncementBar
