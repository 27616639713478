import { SanityClient } from "@sanity/client"
import sanityClient from "@sanity/client"
import imageUrlBuilder from '@sanity/image-url'

const client: SanityClient = sanityClient({
  projectId: 'yt8n50id',
  dataset: process.env.GATSBY_SANITY_DATASET,
  apiVersion: '2023-07-01', // use current UTC date - see "specifying API version"!
  token: Boolean(process.env.SANITY_USE_PREVIEW_TOKEN) ? process.env.SANITY_PREVIEW_TOKEN : process.env.SANITY_TOKEN,
  useCdn: false, // `false` if you want to ensure fresh data
})

export const writeClient: SanityClient = sanityClient({
  projectId: 'yt8n50id',
  dataset: process.env.GATSBY_SANITY_DATASET,
  apiVersion: '2023-07-01', // use current UTC date - see "specifying API version"!
  token: process.env.SANITY_WRITE_TOKEN,
  useCdn: false, // `false` if you want to ensure fresh data
})

export const writeClientStaging: SanityClient = sanityClient({
  projectId: 'yt8n50id',
  dataset: "staging-new",
  apiVersion: '2023-07-01', // use current UTC date - see "specifying API version"!
  token: process.env.SANITY_WRITE_TOKEN,
  useCdn: false, // `false` if you want to ensure fresh data
})

const builder = imageUrlBuilder(client)

export const imageUrlForSource = (source: any) => {
  return builder.image(source)
}

export const fetchData = async (groqQuery: any) => {
  try {
    const data = await client.fetch(groqQuery);
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

export default client
